if(typeof $ !== 'undefined'){
    $(document).ready(function(){
        if($("#banner").length){
            $("#banner").cycle();
        }
        if($("#inventory").length){
            Productos.tomarLista();
        }
    });
}

let sendForm = ()=>{
    let url = '/_ajax/sendMail.ajax.php';
    $.post(url, {
        formulario:true,
        nombre: document.getElementById('fName').value,
        correo: document.getElementById('fMail').value,
        motivo: document.getElementById('fSubject').value,
        mensaje: document.getElementById('fMessage').value
    }, ret => {
        let thanks = '<div class="thanks-message">'+ret.msj+'</div>';
        document.querySelector(".contact .form").innerHTML = thanks;
    }, "json");
}

const Productos = {
    url:"/_ajax/productos.ajax.php",
    idArchivo: 0,
    idCoast:1,
    tomarLista: ()=> {
        $(".inventory-list").html('<div class="mensaje">Loading...</div>');
        $.post(Productos.url, {tomarLista:true, idCoast:Productos.idCoast}, (ret)=> {
            Productos.idArchivo = ret.id_archivo;    
            $(".inventory-list").html(ret.html);
            $(".head .last-update span").html(ret.fecha);
        },"json");
    },
    cambiarCosta: (idC)=>{
        $(".costas a").removeClass("activo");
        $(".costas a:nth-child("+idC+")").addClass("activo");
        Productos.idCoast = idC;
        Productos.tomarLista();
    },
    search: (val)=>{
        if(val.length >= 3){
            $(".inventory-list").html('<div class="mensaje">Loading...</div>');
            $.post(Productos.url, {search:Productos.idArchivo, query:val},function(ret){
                $(".inventory-list").html(ret.html);
            },"json");
        }else if(val.length <= 0){
            Productos.tomarLista();
        }
    }
};